<template>
    <section class="panel">
        <section>
            <h4>协同办公</h4>
            <van-grid>
                <van-grid-item v-for="(item,index) in collaborativeOfficeList" :key="index" :icon="item.photo" :text="item.text" :to="item.path" />
            </van-grid>
        </section>
        <section>
            <h4>人事管理</h4>
            <van-grid>
                <van-grid-item v-for="(item,index) in personnelManagementList" :key="index" :icon="item.photo" :text="item.text" :to="item.path" />
            </van-grid>
        </section>
        <section>
            <h4>财务管理</h4>
            <van-grid>
                <van-grid-item v-for="(item,index) in financialManagementList" :key="index" :icon="item.photo" :text="item.text" :to="item.path" />
            </van-grid>
        </section>
        <section>
            <h4>其他</h4>
            <van-grid>
                <van-grid-item v-for="(item,index) in otherList" :key="index" :icon="item.photo" :text="item.text" :to="item.path" />
            </van-grid>
        </section>
    </section>
</template>
<script>
import Vue from 'vue';
import { Grid, GridItem } from 'vant'; //引入vant九宫格组件
Vue.use(Grid);
Vue.use(GridItem);
export default {
    data() {
        return{
            collaborativeOfficeList:[ //协同办公菜单
                {
                    photo:require('../../assets/workbench/icon1.png'),
                    text:'打卡',
                    path:''
                },
                {
                    photo:require('../../assets/workbench/icon2.png'),
                    text:'工时填报',
                    path:''
                },
                {
                    photo:require('../../assets/workbench/icon3.png'),
                    text:'请假',
                    path:''
                },
                {
                    photo:require('../../assets/workbench/icon4.png'),
                    text:'出差',
                    path:''
                },
                {
                    photo:require('../../assets/workbench/icon5.png'),
                    text:'外出',
                    path:''
                },
                {
                    photo:require('../../assets/workbench/icon6.png'),
                    text:'班次管理',
                    path:''
                },
                {
                    photo:require('../../assets/workbench/icon7.png'),
                    text:'考勤异常',
                    path:''
                },
                {
                    photo:require('../../assets/workbench/icon8.png'),
                    text:'考勤统计',
                    path:''
                },
                {
                    photo:require('../../assets/workbench/icon9.png'),
                    text:'我的审批',
                    path:''
                }
            ],
            personnelManagementList:[ //人事管理菜单
                {
                    photo:require('../../assets/workbench/icon10.png'),
                    text:'入职申请',
                    path:''
                },
                {
                    photo:require('../../assets/workbench/icon11.png'),
                    text:'离职申请',
                    path:'/quitManage'
                },
                {
                    photo:require('../../assets/workbench/icon12.png'),
                    text:'调动申请',
                    path:''
                },
                {
                    photo:require('../../assets/workbench/icon13.png'),
                    text:'续签',
                    path:''
                },
                {
                    photo:require('../../assets/workbench/icon14.png'),
                    text:'加班申请',
                    path:''
                },
                {
                    photo:require('../../assets/workbench/icon15.png'),
                    text:'转正申请',
                    path:''
                },
                {
                    photo:require('../../assets/workbench/icon16.png'),
                    text:'人事申请',
                    path:''
                }
            ],
            financialManagementList:[ //财务管理菜单
                {
                    photo:require('../../assets/workbench/icon17.png'),
                    text:'费用登记',
                    path:''
                },
                {
                    photo:require('../../assets/workbench/icon18.png'),
                    text:'报销申请',
                    path:''
                },
                {
                    photo:require('../../assets/workbench/icon19.png'),
                    text:'对公付款',
                    path:''
                },
                {
                    photo:require('../../assets/workbench/icon20.png'),
                    text:'个人发票',
                    path:''
                },
                {
                    photo:require('../../assets/workbench/icon21.png'),
                    text:'公司发票',
                    path:''
                }
            ],
            otherList:[ //其他菜单
                {
                    photo:require('../../assets/workbench/icon17.png'),
                    text:'反馈记录',
                    path:''
                }
            ]
        }
    },
    methods(){

    }
}
</script>
<style lang="less" scope>
    @import '../../assets/less/workbench.less';
.panel{
    h4{
        font-size: 40px;
        font-weight: 600;
        color: #444B65;
        padding: 40px 34px 0;
    }
    /deep/ .van-grid-item__text{
        font-size: 28px;
        color: #63687B;
    }
    /deep/ .van-icon__image{
        height: auto;
        width: auto;
    }
}

</style>